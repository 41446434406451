<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Campaigns</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/campaign/new" class="mb-2 ma-2"
        >Insert</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="result.data"
      :options.sync="options"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deletePopup(item)">delete</v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="initialize"
        :length="result.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialogLevelRewards" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Level Rewards</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogLevelRewards = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemLevelRewards"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogBigReward" max-width="1600px">
      <v-card>
        <v-card-title>
          <span class="headline">Big Reward</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogBigReward = false"
            >Close</v-btn
          >
        </v-card-actions>
        <v-card-text>
          <json-viewer
            name="df"
            :value="itemBigReward"
            :expand-depth="5"
            theme="my-awesome-json-theme"
            sort
          ></json-viewer>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Warning
        </v-card-title>
        <v-card-text class="text-h5">
          Are you sure you want to delete?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

import rewardType from "@/lib/rewardType";
import campaignType from "../../lib/campaignType";
import router from "@/router";
import {secondsToStringDate} from "../../core/secondToDate";
export default {
  data() {
    return {
      rewardType: rewardType,
      dialogDelete: false,
      dialogEdit: false,
      dialogLevelRewards: false,
      dialogBigReward: false,
      currentItem: {},
      detail: {},
      all: [],
      headers: [
        { text: "Campaign Name", value: "c" },
        { text: "Left Time", value: "l" },
        { text: "Actions", value: "action", sortable: false },
      ],
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      itemLevelRewards: {},
      itemBigReward: {},
      itemsPerPage: 500,
      singleSelect: false,
      selected: [],
      result: [],
      pagination: {
        sortBy: "id",
      },
    };
  },

  
  methods: {
    async deletePopup(item) {
      this.currentItem = item;
      this.dialogDelete = true;
    },
    async deleteItem() {
      this.dialogDelete = false;
      await this.$store.dispatch("campaigns/deleteById", this.currentItem.c);
      await this.initialize();
      window.location.reload()
    },
    async editItem(item) {
      router.push("/campaign/detail/" + item.c);
    },
    async initialize() {
      // eslint-disable-next-line no-console
      console.log("initialize");
      this.result = await this.$store.dispatch("campaigns/getAll");
      this.all = this.$store.state.campaigns.all;
      this.all.data.map(m=>{
        m.c=campaignType.find(x=>x.key===m.c).value
        m.l= secondsToStringDate(m.l-Date.now()/1000)
      })
    },
  },

  async mounted() {
    await this.initialize();
  },

  
};

</script>
